export const scrollPrev = (emblaApi: any) => {
    if (!emblaApi.value) return;
    emblaApi.value?.scrollPrev();
}

export const scrollNext = (emblaApi: any) => {
    if (!emblaApi.value) return;
    emblaApi.value?.scrollNext();
}

export const scrollTo = (emblaApi: any, index: number) => {
    if (!emblaApi.value) return;
    emblaApi.value?.scrollTo(index);
}

export const scrollSnapList = (emblaApi: any) => {
    if (!emblaApi.value) return;
    return emblaApi.value.scrollSnapList()
}

export const selectedScrollSnap = (emblaApi: any) => {
    if(!emblaApi.value) return;
    return emblaApi.value.selectedScrollSnap();
}

export const canScrollPrev = (emblaApi: any) => {
    if(!emblaApi.value) return;
    return emblaApi.value.canScrollPrev();
}

export const canScrollNext = (emblaApi: any) => {
    if(!emblaApi.value) return;
    return emblaApi.value.canScrollNext();
}